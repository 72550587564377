import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { iif, of } from 'rxjs'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'
import {
  MAUS_ERROR,
  MAUS_GET,
  MAUS_RECEIVE,
  MausGetAction,
} from '../../actions/stats'
import { State, getMaus } from '../../reducers'
import { getCurrentYear, getTimeAgo } from '../../utils/time-utils'
import { StatsService } from '../hubtype-api/stats.service'

@Injectable({
  providedIn: 'root',
})
export class StatsEffects {
  maus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MAUS_GET),
      withLatestFrom<MausGetAction, any>(this.store.select(getMaus)),
      map(([action, stats]) => ({ year: action.payload, stats })),
      switchMap(tuple => {
        const year = tuple.year
        const mausYear = tuple.stats[year]
        return iif(
          () =>
            mausYear &&
            !mausYear.error &&
            (year < getCurrentYear() ||
              getTimeAgo(mausYear.timestamp, 'hours') < 4),
          of({ type: MAUS_RECEIVE, payload: { data: mausYear?.data, year } }),
          this.statsService.getMaus(year).pipe(
            map(maus => ({
              type: MAUS_RECEIVE,
              payload: { data: maus, year },
            })),
            catchError(err =>
              of({ type: MAUS_ERROR, payload: { data: err, year } })
            )
          )
        )
      })
    )
  )

  constructor(
    private actions$: Actions,
    private statsService: StatsService,
    private store: Store<State>
  ) {}
}
