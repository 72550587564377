import produce, { Draft } from 'immer'
import { InsightsAction, InsightsActionType } from '../actions/insights'
import { InsightsState, insightsInitialState } from './insights.state'

export function reducer(state: InsightsState, action: InsightsAction) {
  return produce(
    (draftState: Draft<InsightsState>, appraisalAction: InsightsAction) => {
      switch (appraisalAction.type) {
        case InsightsActionType.SET_DATE_FROM:
          draftState.dateFrom = appraisalAction.payload
          break

        default:
          return draftState
      }
    },
    insightsInitialState
  )(state, action)
}
