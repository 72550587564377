import produce, { Draft } from 'immer'
import { updateObjectListById } from 'utils/array-utils'
import { AiAction, AiActionType } from '../actions/ai'
import { AiState, aiInitialState } from './ai.state'

export function reducer(state: AiState, action: AiAction) {
  return produce((draftState: Draft<AiState>, appraisalAction: AiAction) => {
    switch (appraisalAction.type) {
      case AiActionType.SET_PROJECT_LIST:
        draftState.aiProjects = appraisalAction.payload
        return draftState
      case AiActionType.UPDATE_PROJECT:
        draftState.aiProjects = updateObjectListById(
          draftState.aiProjects,
          appraisalAction.payload
        )
        return draftState
      default:
        return draftState
    }
  }, aiInitialState)(state, action)
}
