import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { HubtypePusherApp } from 'models/hubtype-pusher-app'
import { map, switchMap } from 'rxjs/operators'
import {
  InfrastructureActionType,
  UpdatePusherSettings,
} from 'src/app/actions/infrastructure'
import { State } from 'src/app/reducers'
import { OrganizationService } from '../hubtype-api/organization.service'

@Injectable({
  providedIn: 'root',
})
export class PusherEffects {
  refreshPusherSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfrastructureActionType.FETCH_PUSHER_SETTINGS),
      switchMap(() =>
        this.organizationService
          .getMyOrganization()
          .pipe(
            map(
              org =>
                new UpdatePusherSettings(HubtypePusherApp.fromOrganization(org))
            )
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    @Inject('organizationService')
    private organizationService: OrganizationService
  ) {}
}
